html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

html {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  overflow: auto;
}

body {
  padding: 0;
  overflow: auto;
}

.App {
  text-align: left;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

legend{
  width: inherit !important;
  padding: 0 10px !important;
  border-bottom: none !important;
}

.fields-dropdown{
  width: 900px;
}

.menu-outer-top {
  display: flex;
  align-items: flex-start;
}

.url-block div {
  min-height: 70px;
}

.url-block div span{
  color: whitesmoke;
}

.Toastify__toast-container--top-right {
  width: 600px!important;
}

.ag-theme-alpine {
  --ag-font-size: 12px !important;
}

.container {
  max-width: 100% !important;
}

.main-container {
  height: calc(100vh - 50px) !important;
  min-height: calc(100vh - 5vh) !important;
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#data-table {
  height: 100%;
  width: 100%;
}

.table-container{
  height: calc(100vh - 180px);
  min-height: 200px;
}

.basic-table-container {
  height: calc(100vh - 50px);
  min-height: 200px;
}

.landing-container {
  height: calc(100vh - 100px);
  min-height: 600px;
}

.navbar-brand {
  padding: 0px !important;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-card {
  text-align: left; /* Keep text content left-aligned within the Card */
}

.left-aligned-content {
  text-align: left; /* Ensure content inside Card.Body is left-aligned */
}

.ag-center-cols-container{
  width: 100% !important;
}

.carousel-control-prev, .carousel-control-next{
  width: 50px !important;
}

.carousel {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.carousel-indicators {
  position: relative !important;
}

.remove-card-icon{
  position: absolute;
  top: 0;
  right: 5px;
  margin-top: 0.3em;
}

.cell-renderer-fields-set-box{
  border: 1px solid #A9A9A9;
  margin-bottom: 2px;
  border-radius: 5px;
  padding: 2px 3px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.subscription-pair {
  background-color: #72A9E3 !important;
}

.subscription-row {
  background-color: inherit !important;
}

.running-subscription{
  background-color: #ACD8A7 !important;
}

.copy-selected-option-button{
  cursor: pointer;
}

.copy-selected-option-button:hover {
  background-color: transparent !important;
  opacity: 0.7;
}

.filter-fields-select__multi-value__remove{
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.icon-button{
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.permission-card{
  max-width: calc(100% / 3) !important;
}

.my-loading{
  height: 100% !important;
  overflow-y: auto !important;
}

._loading_overlay_wrapper--active{
  height: 100% !important;
}

.internal-label-small-text{
  font-size: 12px;
  margin-left: 5px;
}

legend{
  padding: 0 !important;
}

.codeMarker  {
  background: #ffff99;
  position: absolute;
  z-index: 20;
}

.ReactToastify__toast {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.badge-button{
  cursor: pointer;
}

._loading_overlay_wrapper{
  height: 100% !important;
}

.version-navbar{
  position: absolute;
  bottom: 0;
  left: 69px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.55);
}

.field-card-header{
  padding-bottom: 0 !important;
  padding-top: 5px !important;
}

.field-card-body{
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}

.blockquote-card{
  margin-top: 20px !important;
  font-size: medium !important;
  margin-bottom: 3px !important;
}

.blockquote-card-loading{
  margin-top: 0 !important;
  font-size: medium !important;
  margin-bottom: 0 !important;
}

.container-top{
  margin-top: 35px;
  position: absolute;
  width: 100%;
}

.navbar-slim{
  padding: 0 !important;
}

.signout-button-navbar {
  margin-left: 40px;
  max-width: 120px;
  height: 32px;
  margin-top: 4px
}

.regexp-cell-wrapper{
  overflow-wrap: break-word;
  width: calc(100% - 100px);
  display: flow;
  float: right;
}

.single-cell-wrapper{
  overflow-wrap: break-word;
  width: calc(100% - 100px);
  display: contents;
  float: right;
}

.custom-warning-badge{
  border-radius: 0.375em;
  background-color: rgb(255, 193, 7);
  color: white;
  padding: 0.35em 0.65em;
  line-height: 1;
  font-weight: 700;
  font-size: 0.75em;
}

.entitlements-permissions-cell{
  min-width: calc(100% - 100px);
}

/* Overlay styling */
.loading-overlay {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  flex-direction: column;
}

/* Spinner styling */
.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3); /* Light border */
  border-top: 4px solid white; /* White solid border at the top */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

.loading-text{
  color: white;
  margin-top: 10px;
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.react-select-invalid div{
  border-color: #dc3545;
}

.invalid-filter{
  display: block;
  color: #dc3545;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  width: 100%;
}

.dropdown-username{
  padding-left: 5px !important;
  padding-right: 5px !important;
  color: whitesmoke !important;
}

.dropdown-username:hover{
  color: whitesmoke !important;
  opacity: 0.7 !important;
}

.filter-fields-select__value-container div {
  width: auto !important; /* Override inline width */
  white-space: normal; /* Allow text wrapping if needed */
}